import './style.css';
import useFetch from '@Hooks/UseFetch';
import { PersonalData } from '@Types/PersonalData';

const Imprint = () => {
  const {
    response: { data, loading, error },
  } = useFetch<PersonalData>('https://bitflow.dev/api/imprint/contact', {});

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        <p>An error occurred when loading contact data, please try again later!</p>
      </div>
    );
  }

  return (
    <div className="imprint">
      <h1>Imprint</h1>
      <h2>Contact</h2>
      <h3>Address</h3>
      <div>
        <p>{data?.company}</p>
        <p>{data?.address_line}</p>
        <p>
          {data?.zip_code} {data?.city}
        </p>
        <p>
          {data?.state}, {data?.country}
        </p>
        <p>
          <a href={'mailto:' + data?.email}>{data?.email}</a>
        </p>
        <div className="spacing1" />
        <p>VAT-ID: {data?.vat}</p>
        <div className="spacing1" />
        <p>Represented by</p>
        <p>{data?.person}</p>
      </div>
    </div>
  );
};

export default Imprint;
