const Contact = () => {
  return (
    <div className="center">
      <h2>Contact</h2>
      <p>Expected a cool form to contact me here, huh?</p>
      <p>Guess you gotta mail to me:</p>
      <a href="mailto:contact@bitflow.dev">
        <code>contact@bitflow.dev</code>
      </a>
    </div>
  );
};
export default Contact;
