import ProjectsData from '@Data/projects.json';

import './style.css';

const Projects = () => {
  let projects = ProjectsData;
  let index = 0;
  return (
    <div>
      <h2>Public Projects</h2>
      {projects.map((project) => (
        <div key={project.title} className={'project-container ' + (index++ % 2 === 0 ? 'left' : 'right')}>
          <div className="project">
            <div className="head">
              <h3>
                <a rel="noreferrer" target="_blank" href={project.link}>
                  {project.title}
                </a>
              </h3>
              <h4>{project.subTitle}</h4>
            </div>
            {project.image != null && (
              <picture>
                <source
                  media="(max-width: 1000px)"
                  srcSet={
                    '/cdn-cgi/image/format=auto,fit=contain,width=360/assets/' +
                    project.image +
                    ' 400w,' +
                    '/cdn-cgi/image/format=auto,fit=contain,width=500/assets/' +
                    project.image +
                    ' 600w,' +
                    '/cdn-cgi/image/format=auto,fit=contain,width=720/assets/' +
                    project.image +
                    ' 800w,' +
                    '/cdn-cgi/image/format=auto,fit=contain,width=860/assets/' +
                    project.image +
                    ' 1000w,'
                  }
                />
                <source media="(min-width: 1000px)" srcSet={'/cdn-cgi/image/format=auto,fit=contain,width=256/assets/' + project.image} />
                <img
                  srcSet={
                    '/cdn-cgi/image/format=auto,fit=contain,width=360/assets/' +
                    project.image +
                    ' 400w,' +
                    '/cdn-cgi/image/format=auto,fit=contain,width=500/assets/' +
                    project.image +
                    ' 600w,' +
                    '/cdn-cgi/image/format=auto,fit=contain,width=720/assets/' +
                    project.image +
                    ' 1000w,' +
                    '/cdn-cgi/image/format=auto,fit=contain,width=720/assets/' +
                    project.image +
                    ' 1280w,'
                  }
                  alt="Project"
                />
              </picture>
            )}
            <div className="desc" dangerouslySetInnerHTML={{ __html: project.description }} />
          </div>
        </div>
      ))}
    </div>
  );
};
export default Projects;
