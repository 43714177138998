import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <h2>Home</h2>

      <p className="center spacing2">Welcome to Bitflow Development</p>

      <h3>About me</h3>
      <p>
        Hi! I'm Alex, the founder of Bitflow Development. I'm pretty much interested in anything about software development, spanning over the
        sysadmin part, devops, backends, middlewares and lastly frontends & mobile applications.
      </p>
      <div className="spacing1" />
      <p>Though; I do prefer the backend part. I'd not call myself the best designer, but it works.</p>
      <div className="spacing1" />
      <p>
        I've been working on various projects throughout the past few years and decided to create my company in April 2020, which became my full-time
        job in January 2021.
      </p>
      <h3>Projects</h3>
      <p>
        Since you're here already, check out my current <Link to="/portfolio">Portfolio</Link> and <Link to="/projects">Public Projects</Link>!
      </p>
    </div>
  );
};

export default Home;
