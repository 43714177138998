import { useEffect, useReducer, useRef } from 'react';

interface Response<T> {
  data?: T;
  loading?: Boolean;
  error?: Error;
}

interface State<T> {
  response: Response<T>;
}

type Cache<T> = { [url: string]: T };

// discriminated union type
type Action<T> = { type: 'response'; payload: State<T> };

/**
 * This is a custom usefetch hook I made
 * @param {string} url
 * @param {RequestInit} options
 * @returns {State}
 **/
function useFetch<T>(url: string, options?: RequestInit): State<T> {
  const cache = useRef<Cache<T>>({});

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false);

  const initialState: State<T> = {
    response: { loading: false, data: undefined, error: undefined },
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'response':
        return { ...initialState, ...action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    // Do nothing if the url is not given
    if (!url) return;

    const fetchData = async () => {
      dispatch({ type: 'response', payload: { response: { ...state.response, loading: true } } });

      // If a cache exists for this url, return it
      if (cache.current[url]) {
        dispatch({ type: 'response', payload: { response: { ...state, data: cache.current[url], loading: false } } });
        return;
      }

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const data = (await response.json()) as T;
        cache.current[url] = data;
        if (cancelRequest.current) return;
        dispatch({ type: 'response', payload: { response: { ...state.response, data, loading: false } } });
      } catch (error) {
        if (cancelRequest.current) return;

        dispatch({ type: 'response', payload: { response: { ...state.response, error: error as Error, loading: false } } });
      }
    };

    void fetchData();

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return state;
}

export default useFetch;
