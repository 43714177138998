import { Outlet, NavLink, Link } from 'react-router-dom';

import './style.css';

const pages = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Portfolio',
    path: '/portfolio',
  },
  {
    name: 'Projects',
    path: '/projects',
  },
  {
    name: 'Contact',
    path: '/contact',
  },
  {
    name: 'Commission/Work',
    path: '/commission',
  },
];

const Wrapper = () => {
  const toggleNav = () => {
    if (window.innerWidth > 1000) {
      return;
    }
    let root = document.documentElement;
    let value = root.style.getPropertyValue('--nav-menu-display');
    if (value === 'block') {
      root.style.setProperty('--nav-menu-display', 'none');
    } else {
      root.style.setProperty('--nav-menu-display', 'block');
    }
  };

  return (
    <div id="content">
      <div className="wrapper">
        <div id="header">
          <h1>Bitflow Development</h1>
          <nav className="center">
            {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
            <a href={void 0} className="icon mobile" onClick={toggleNav}>
              <i className="fa fa-bars" />
            </a>
            <ul>
              {pages.map((page) => (
                <li key={page.path.substring(0, page.path.length)}>
                  <NavLink to={page.path} className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleNav}>
                    {page.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div id="content-wrapper">
          <Outlet />
        </div>
      </div>
      <footer>
        <p className="right">
          &copy; Bitflow Development 2021-2022 | <Link to="/imprint">Imprint</Link>
        </p>
      </footer>
    </div>
  );
};

export default Wrapper;
