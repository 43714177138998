import './style.css';

const Portfolio = () => {
  return (
    <div>
      <h2>Portfolio</h2>

      <div className="portfolio-content">
        <h3 className="portfolio-title">Experience in different fields of work</h3>
        <ul className="portfolio">
          <li>Backend Software Engineering</li>
          <li>DevOps & Cloud</li>
          <li>Linux Sys-Admin</li>
          <li>Software Architecture</li>
          <li>Frontend & Design</li>
        </ul>

        <h3 className="portfolio-title">Fluent in those programming languages</h3>
        <ul className="portfolio">
          <li>Rust</li>
          <li>Java</li>
          <li>Kotlin</li>
          <li>Go</li>
          <li>C</li>
          <li>C++</li>
          <li>C#</li>
          <li>Objective-C</li>
          <li>JavaScript</li>
          <li>TypeScript</li>
          <li>Bash</li>
        </ul>

        <h3 className="portfolio-title">Familiar with software/products</h3>
        <ul className="portfolio">
          <li>Google Cloud Platform</li>
          <li>Amazon Web Services</li>
          <li>Hetzner Cloud</li>
          <li>Cloudflare Pages</li>
          <li>Cloudflare Workers</li>
          <li>Kubernetes</li>
          <li>RabbitMQ</li>
          <li>MySQL/MariaDB</li>
          <li>PostgreSQL</li>
          <li>Redis</li>
          <li>MongoDB</li>
          <li>Prometheus</li>
          <li>Grafana</li>
          <li>HAProxy</li>
          <li>Traefik</li>
          <li>NGINX</li>
          <li>Github/Gitlab</li>
        </ul>
      </div>
    </div>
  );
};

export default Portfolio;
